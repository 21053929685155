<template>
  <div class="mod-orglicense">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-select v-model="searchForm.freezerId" clearable filterable>
            <el-option
              v-for="(item, index) in freezerList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)"> 查询 </el-button>
          <el-button
            v-if="isAuth('cc:banner:save')"
            type="primary"
            @click="$dialog('bannerAddOrUpdate', '', false)"
          >
            新增
          </el-button>
          <el-button
            v-if="isAuth('cc:banner:delete')"
            type="danger"
            :disabled="dataListSelections.length <= 0"
            @click="deleteHandle()"
          >
            批量删除
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      stripe
      border
      style="width: 100%"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      />
      <el-table-column
        prop="freezerName"
        header-align="center"
        align="center"
        label="冷柜名称"
      />
      <el-table-column
        prop="imageUrl"
        header-align="center"
        align="center"
        label="轮播图"
      >
        <template slot-scope="scope">
          <img
            style="height: 50px; width: 50px"
            :src="scope.row.imageUrl"
            @click="openImg(scope.row.imageUrl)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        header-align="center"
        align="center"
        label="轮播图名字"
      >
      </el-table-column>
      <el-table-column
        prop="isShow"
        header-align="center"
        align="center"
        label="是否显示"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isShow"
            @change="changeBannerShow(scope.row.id, scope.row.isShow)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('cc:banner:id')"
            type="text"
            size="small"
            @click="$dialog('bannerAddOrUpdate', scope.row.id, true)"
          >
            查看
          </el-button>
          <el-button
            v-if="isAuth('cc:banner:update')"
            type="text"
            size="small"
            @click="$dialog('bannerAddOrUpdate', scope.row.id, false)"
          >
            修改
          </el-button>
          <el-button
            v-if="isAuth('cc:banner:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
    />
    <!-- 弹窗, 新增 / 修改 -->
    <banner-add-or-update
      v-if="bannerAddOrUpdateVisible"
      ref="bannerAddOrUpdate"
      @refreshDataList="getDataList"
    />
  </div>
</template>

<script>
import { threeListMixin, listMixin, normal } from '@/mixins';
import BannerAddOrUpdate from './banner-add-or-update';
export default {
  mixins: [threeListMixin, listMixin, normal],
  components: {
    BannerAddOrUpdate,
  },
  data() {
    return {
      searchForm: {
        freezerId: '',
      },
      dataList: [],
      freezerList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      bannerAddOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList(1);
    this.getFreezerList();
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      this.$http({
        url: '/cc/banner/page',
        method: 'get',
        params: {
          page: page ? page : this.pageIndex,
          perPage: this.pageSize,
          freezerId: this.searchForm.freezerId,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.data.items;
          this.totalPage = data.data.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    getFreezerList() {
      this.$http({
        url: `/cc/freezer/all`,
        method: 'get',
        params: { isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
        } else {
          this.freezerList = [];
        }
      });
    },

    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    changeBannerShow(id, isShow) {
      this.$http({
        url: `/cc/banner/ids/update/boolean`,
        method: 'post',
        data: [
          {
            id: id,
            isShow: isShow,
          },
        ],
        after: () => {
          this.getCategoryList();
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定对数据进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/cc/banner/ids/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
